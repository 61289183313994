var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-0"},[(_vm.loading)?_c('a-skeleton',{attrs:{"active":""}}):_c('div',{staticClass:"col-md-12 p-0"},[_c('a-collapse',{staticClass:"mb-5",model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((_vm.data),function(value,key){return _c('a-collapse-panel',{key:key,attrs:{"header":key,"disabled":false}},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":value.reductions,"pagination":false},scopedSlots:_vm._u([{key:"feeName",fn:function(text){return _c('span',{},[(text == 'inscription')?_c('p',[_vm._v(" "+_vm._s(text)+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.formatFeeName(text))+" ")])])}},{key:"repReductionValue",fn:function(text, record){return _c('span',{},[(
                _vm.editingReduction &&
                _vm.editingReduction.feeName == record.feeName &&
                _vm.editingReduction.payment == value.payment
              )?_c('span',[_c('a-input-number',{attrs:{"min":0,"max":_vm.reductionType == 'pourcentage'
                    ? 100
                    : Number(record.feeBeforeRed)},model:{value:(_vm.editingReduction.value),callback:function ($$v) {_vm.$set(_vm.editingReduction, "value", $$v)},expression:"editingReduction.value"}})],1):_c('a-tag',{attrs:{"color":"green"}},[(_vm.reductionType == 'dinar')?_c('span',[_vm._v(" "+_vm._s((record.feeBeforeRed - record.feeAfterReduction).toFixed(2))+" TND ")]):_c('span',[_vm._v(" "+_vm._s(Number(text).toFixed(2))+" % ")])])],1)}},{key:"feeAfterReduction",fn:function(text, record){return _c('span',{},[(
                _vm.editingReduction &&
                _vm.editingReduction.feeName == record.feeName &&
                _vm.editingReduction.payment == value.payment
              )?_c('span',[(_vm.reductionType == 'dinar')?_c('span',[_vm._v(" "+_vm._s((record.feeBeforeRed - _vm.editingReduction.value).toFixed(2))+" TND ")]):_c('span',[_vm._v(" "+_vm._s(( record.feeBeforeRed - (record.feeBeforeRed * _vm.editingReduction.value) / 100 ).toFixed(2))+" ")])]):_c('span',[_vm._v(" "+_vm._s(Number(text).toFixed(2))+" ")])])}},{key:"action",fn:function(text, record){return _c('div',{},[(!record.isPayed)?_c('span',[(
                  !(
                    _vm.editingReduction &&
                    _vm.editingReduction.feeName == record.feeName &&
                    _vm.editingReduction.payment == value.payment
                  )
                )?_c('a-button',{staticClass:"mr-3",attrs:{"type":"primary","disabled":_vm.editingReduction &&
                  (_vm.editingReduction.feeName != record.feeName ||
                    _vm.editingReduction.payment != value.payment)},on:{"click":() => {
                    _vm.editingReduction = {
                      feeName: record.feeName,
                      payment: value.payment,
                      value:
                        _vm.reductionType == 'dinar'
                          ? record.feeBeforeRed - record.feeAfterReduction
                          : record.repReductionValue,
                      record: record,
                    };
                  }}},[_vm._v(" "+_vm._s(_vm.$t("action.modifier"))+" ")]):_vm._e(),(
                  _vm.editingReduction &&
                  _vm.editingReduction.feeName == record.feeName &&
                  _vm.editingReduction.payment == value.payment
                )?_c('div',[_c('a-button',{staticClass:"mr-3",attrs:{"loading":_vm.loadingSave,"disabled":_vm.loadingSave,"type":"primary"},on:{"click":_vm.saveReduction}},[_vm._v(" "+_vm._s(_vm.$t("action.enregistrer"))+" ")]),_c('a-button',{staticClass:"mr-3",on:{"click":() => {
                      _vm.editingReduction = null;
                    }}},[_vm._v(" "+_vm._s(_vm.$t("action.annuler"))+" ")])],1):_vm._e()],1):_vm._e()])}}],null,true)})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }