<template>
  <div>
    <a-form :form="form" class="card-header p-5" @submit="addActivity">
      <div class="row">
        <div class="col-md-12">
          <a-form-item>
            <a-select
              v-decorator="[
                'activity',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.activiteRequis'),
                    },
                  ],
                },
              ]"
              show-search
              :placeholder="$t('paiement.listeActivites')"
              style="width: 100%"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="(v) => selectActivity(v)"
            >
              <a-select-option
                v-for="activtiy in activities"
                :key="activtiy._id"
                :value="activtiy._id"
              >
                {{ activtiy.designation }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              :allowClear="false"
              :disabled-date="disabledDate"
              @change="
                (start) => {
                  currentFeesRange.start = start;
                  setIncludedFees();
                }
              "
              v-decorator="[
                'start',
                {
                  defaultValue: moment(Date.now()),
                  rules: [
                    {
                      required: true,
                      message: $t('paiement.dateDebutRequis'),
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item>
            <a-date-picker
              @change="
                (end) => {
                  currentFeesRange.end = end;
                  setIncludedFees();
                }
              "
              :disabled-date="disabledEndDate"
              v-decorator="[
                'end',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :format="dateFormat"
              style="width: 100%"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row" v-if="activityInfos._id">
        <a-divider>Selectionner le mode de paimenet </a-divider>

        <div class="col-lg-3">
          <h6 for="">{{ $t("inscription.fraisInscri") + " (dt)" }}</h6>
          <p>{{ activityInfos.inscription }}</p>
        </div>
        <div
          @click="changeModeP('M')"
          :class="[
            'col-lg-4',
            'mr-2',
            'pt-2',
            modePaiement === 'M' ? 'custom-border-active' : 'custom-border',
          ]"
        >
          <h6 for="">{{ $t("inscription.fraisMensuel") + " (dt)" }}</h6>
          <p>{{ activityInfos.monthly }}</p>
        </div>
        <div
          @click="changeModeP('T')"
          :class="[
            'col-lg-4',
            'pt-2',
            modePaiement === 'T' ? 'custom-border-active' : 'custom-border',
          ]"
          v-if="activityInfos.tranches"
        >
          <h6 for="">Frais des tranches (dt)</h6>
          <li v-for="t in activityInfos.tranches" :key="t.trancheNumber">
            {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
          </li>
        </div>
        <a-alert
          width="100%"
          class="mt-2"
          :message="
            'Le mode de paiment selectionné est ' +
            (modePaiement === 'T' ? 'par tranches' : 'mensuelle')
          "
          type="info"
          show-icon
        />
        <a-divider>Les frais à payer</a-divider>
      </div>

      <div class="row" v-if="activityInfos._id">
        <div class="col-lg-9 mb-2">
          <div v-for="(value, key, index) in includedFees" :key="key">
            <div class="row mt-1">
              <div class="col-6">
                <a-form-item
                  :class="index == 0 ? 'mt-0 mb-0' : 'm-0'"
                  :label="index == 0 ? 'Frais' : ''"
                >
                  <a-tag style="width: 100%; font-size: 15px" class="mt-1"
                    >{{ formatFeeName(key) }}
                  </a-tag>
                </a-form-item>
              </div>
              <div class="col-6">
                <a-form-item
                  :class="index == 0 ? 'mt-0 mb-0' : 'm-0'"
                  :label="index == 0 ? `Inclus` : ''"
                >
                  <a-switch
                    :checked="includedFees[key]"
                    :key="key"
                    @change="
                      (e) => {
                        changeIncludedFees(key, e);
                      }
                    "
                  >
                    <a-icon slot="checkedChildren" type="check" />
                    <a-icon slot="unCheckedChildren" type="close" />
                  </a-switch>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-5">
          <a-button
            type="primary"
            style="width: 100%"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            icon="tags"
            :ghost="false"
          >
            {{ $t("action.ajouter") }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import store from "store";
import { formatFeeName, dateRange } from "@/utilities/settings";

const actvitiyPaymentsFormater = (activityFees, studentId, studentList) => {
  const getClassRoom = (studentId, studentList) => {
    try {
      return studentList.filter((s) => s._id == studentId)[0].classRoomName;
    } catch (error) {
      return " ";
    }
  };
  let status = "impayed";
  if (activityFees.inscription) {
    if (!activityFees.activity.once && activityFees.months) {
      status = "payed";
      for (const [key, value] of Object.entries(activityFees.months)) {
        if (!value) {
          status = "impayed";
          break;
        }
      }
    }
  }
  return {
    status,
    inscription: activityFees.inscription,
    months: activityFees.months,
    _id: activityFees._id,
    fullName:
      activityFees.student.firstName + " " + activityFees.student.lastName,
    activity: activityFees.activity.designation,
    activityDetails: activityFees.activity,
    class: getClassRoom(studentId, studentList),
  };
};

const monthly = (start, end) => {
  let monthObj = Object();
  dateRange(start, end).forEach((date) => (monthObj[date] = false));
  return monthObj;
};
export default {
  computed: mapState(["settings", "user"]),
  props: {
    studentId: {
      required: true,
    },
  },
  created() {
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    this.form = this.$form.createForm(this, { name: "addActivityForm" });
    apiClient
      .post("/activities/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            designation: 1,
            start: 1,
            end: 1,
            inscription: 1,
            monthly: 1,
            once: 1,
            tranches: 1,
          },
        },
      })
      .then((res) => (this.activities = res.data))
      .catch((e) => this.$message.error(this.$t("error.aucActivite")));

    this.getActivitiesPayments();
  },

  data() {
    return {
      reductionType: "dinar",
      currentFeesRange: {},
      includedFees: {},
      loadingClasse: false,
      dateFormat: "DD-MM-YYYY",
      studentsData: [],
      studentList: [],
      classList: [],
      activities: [],
      actvitiyFees: [],
      activitySelected: {
        start: moment(Date.now()),
        end: null,
      },
      activityInfos: {},
      modePaiement: "M",
    };
  },

  methods: {
    formatFeeName,
    changeIncludedFees(key, e) {
      this.includedFees[key] = e;
      this.$forceUpdate(); // Force re-render
    },
    changeModeP(v) {
      this.modePaiement = v;
      this.setIncludedFees();
    },
    moment,
    disabledDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      const { start, end } = this.activitySelected;
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    disabledEndDate(current) {
      if (!this.activitySelected.end) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.end = moment(schoolarYear.end);
      }
      if (!this.activitySelected.start) {
        const schoolarYears = this.settings.schoolarYears;
        const activeSchoolarYear = store.get("app.settings.activeSchoolarYear");
        const schoolarYear = schoolarYears.filter(
          (sc) => sc._id == activeSchoolarYear
        )[0];
        this.activitySelected.start = moment(schoolarYear.start);
      }
      let { start, end } = this.activitySelected;
      if (this.form.getFieldValue("start"))
        start = this.form.getFieldValue("start");
      return (current && current.isAfter(end)) || current.isBefore(start);
      //return current && current < moment().endOf("day");
    },
    selectActivity(val) {
      const activity = [...this.activities].filter((ac) => ac._id == val);
      const { start, end } = activity[0];
      this.activityInfos = activity[0];
      this.activitySelected.start = moment(start);
      this.activitySelected.end = moment(end);
      this.setIncludedFees();
    },
    validateMonths(activityFees, range) {
      let { start, end } = range;
      let result = true;
      if (!end) end = activityFees.activity.end;
      const newMonthly = monthly(start, end);
      const existMonthly = activityFees.months;
      try {
        if (
          Object.keys(existMonthly).forEach((value) => {
            if (Object.keys(newMonthly).includes(value)) result = false;
          })
        )
          result = false;
      } catch {
        result = false;
      }
      return result;
    },
    addActivity(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          let already = false;

          this.actvitiyFees
            .filter((a) => a.activityDetails._id == values.activity)
            .forEach((activityFees) => {
              if (
                !this.validateMonths(activityFees, {
                  start: values.start,
                  end: values.end,
                })
              )
                already = true;
            });
          if (already) {
            this.loadingClasse = false;
            this.$message.warning(this.$t("error.eleveDejaInscritPourMois"));
          } else {
            //only get selected fees and set false for unpaid status
            const filteredRepReduction = Object.keys(this.includedFees)
              .filter((key) => this.includedFees[key] === true)
              .reduce((obj, key) => {
                obj[key] = false;
                return obj;
              }, {});
            console.log(values);
            let repReduction = { ...filteredRepReduction };
            const data = {
              start: values.start,
              end: values.end || this.activityInfos.end || undefined,
              student: this.studentId,
              activity: values.activity,
              inscription: values.inscription,
              typeAS: "A",
              mode: this.modePaiement,
              repReduction: repReduction,
            };
            if (this.modePaiement == "M") data.months = filteredRepReduction;
            else data.tranches = filteredRepReduction;
            //default rep reduction is 0
            data.repReduction = Object.keys(filteredRepReduction).reduce(
              (obj, key) => {
                obj[key] = 0;
                return obj;
              },
              {}
            );
            data.repReduction["inscription"] = 0;

            /*  console.log(data.repReduction);
            this.loadingClasse = false;*/
            apiClient
              .put(
                "/payment",
                values.end
                  ? {
                      ...data,
                      end: values.end,
                    }
                  : data
              )
              .then((res) => {
                this.$message.success(this.$t("success.inscriAjout"));
                this.$emit("updateActivityInscription");
              })
              .catch((e) => {
                console.error(e.stack);
                this.$message.error(this.$t("error.ajout"));
              })
              .finally(() => (this.loadingClasse = false));
          }
        }
      });
      //this.form.resetFields();
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async getActivitiesPayments() {
      this.tableLeading = true;
      await apiClient
        .post("/payment/filter", {
          query: {
            status: "active",
            typeAS: "A",
            student: this.studentId,
          },
        })
        .then(
          (res) =>
            (this.activityFees = res.data.map((elem) =>
              actvitiyPaymentsFormater(elem, elem.student._id, this.studentList)
            ))
        )
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.aucInscri"));
        })
        .finally(() => {
          this.tableLeading = false;
        });
    },
    setIncludedFees() {
      const activeSchoolarYear = this.settings.activeSchoolarYear;
      const schoolarYears = this.settings.schoolarYears;
      let start = this.currentFeesRange.start || this.activityInfos.start;
      let end = this.currentFeesRange.end || this.activityInfos.end;
      schoolarYears.forEach((elem) => {
        if (elem._id == activeSchoolarYear) {
          if (!end) end = elem.end;
          if (!start) start = elem.start;
        }
      });

      const range = this.dateRange(
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD")
      );

      this.includedFees = {};

      if (this.activityInfos.monthly && this.modePaiement == "M") {
        range.forEach((month) => {
          this.includedFees[month] = true;
        });
      }
      if (this.activityInfos.tranches && this.modePaiement == "T") {
        this.activityInfos.tranches.forEach((tranche) => {
          this.includedFees[tranche.trancheNumber] = true;
        });
      }
    },

    dateRange,
  },
};
</script>
<style scoped>
.custom-tag .ant-tag {
  padding: 4px 7px 4px 7px;
  font-size: 13px;
  margin-left: 3px;
}

.custom-tag .ant-tag:hover {
  cursor: pointer;
}
.custom-border {
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  cursor: pointer;
}
.custom-border-active {
  border: 3px solid #22bb33;
  border-radius: 0.25rem;
  cursor: pointer;
}
</style>
