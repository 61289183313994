<template>
  <div>

    <!--begin::Chart-->
    <apexchart class="statistics-widget-3-chart card-rounded-bottom" :options="options" :series="series" :height="300"
      type="area"></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['data', 'studentName', 'subject'],
  data() {
    return {
      options: {},
      series: [],
      labelColor: '#3F4254',
      colors: ['#D9214E', '#67c23a', '#D9214E'],
      lightColors: ['#F1FAFF', '#e8fff3', '#F1FAFF'],
    }
  },

  watch: {
    data: function (value) {
      this.updateChart()
    },

    className: function (value) {
      this.updateChart()
    },


    subject: function () {
      this.updateChart()
    },
  },

  created() {
    this.updateChart()
  },

  methods: {
    updateChart() {
      const attendance = this.data.filter((a) => !this.subject || a.schedule.subject == this.subject)

      const maxDate = moment.max(
        attendance.map((att) => moment(att.date)),
      )

      const minDate = moment.min(
        attendance.map((att) => moment(att.date)),
      )

      const days = []
      const absentSerie = []
      const delaySerie = []

      const currDate = minDate.startOf('day').subtract(1, 'days')
      const lastDate = maxDate.startOf('day')

      while (currDate.add(1, 'days').diff(lastDate) <= 0) {
        days.push(currDate.format('DD MMMM'))
        absentSerie.push(
          attendance.filter(
            (att) =>
              new Date(att.date).getDate() == currDate.date() &&
              new Date(att.date).getMonth() == currDate.month() && att.type == 'Absence',
          ).length,
        )

        delaySerie.push(
          attendance.filter(
            (att) =>
              new Date(att.date).getDate() == currDate.date() &&
              new Date(att.date).getMonth() == currDate.month() && att.type == 'Retard',
          ).length,
        )
      }

      this.updateOptions(days)
      this.updateSeries(delaySerie, absentSerie)
    },

    updateSeries(delaySerie, absentSerie) {
      this.series = [
        {
          name: 'Retard',
          data: delaySerie,
          color: '#ffc107',
        },
        {
          name: 'Absence',
          data: absentSerie,
          color: '#D9214E',
        },
      ]
    },
    updateOptions(days) {
      this.options = {
        chart: {
          height: 300,
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          color: this.colors,
        },
        xaxis: {
          type: 'string',
          categories: days,
          labels: {
            show: false,
            style: {
              colors: this.labelColor,
              fontSize: '12px',
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {},
        },
        fill: {
          type: 'gradient',
          gradient: {
            stops: [0, 100],
          },
        },
        color: this.colors,
        markers: {
          color: this.colors,
          strokeColor: this.lightColors,
          strokeWidth: 3,
        },
      }
    },
  },
}
</script>
