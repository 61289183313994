<template>
  <div>
    <ul class="list-unstyled">
      <li :class="$style.item">
        <a href="#" :class="$style.itemLink">
          <div :class="$style.itemPic">
            <i class="fa fa-plus" />
          </div>
          <div class="mr-2">
            <div>Gérer</div>
            <div class="text-muted">Consulter</div>
          </div>
          <div :class="$style.itemAction">
            <span />
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "KitList5",
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
