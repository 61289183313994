<template>
  <div class="card">
    <div class="card-body">
      <div class="mb-3">
        <!-- <a-button
          type="primary"
          @click="generatePdf"
          :loading="printLoading"
          :disabled="printLoading"
        >
          <a-icon type="file-pdf" v-if="!printLoading" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>-->

        <a-range-picker
          :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
          format="DD-MM-YYYY"
          v-model="dateRangeData"
          :mode="mode"
          @change="
            (val) => {
              handleDateRange(val);
            }
          "
        />
      </div>
      <div>
        <a-table
          @change="tableChanged"
          rowKey="multiTransactionSerial"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="tableLoading"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="multiTransactionSerial" slot-scope="text">
            <a-tag color="blue">{{ text }}</a-tag>
          </template>
          <template slot="typeReglement" slot-scope="text, record">
            <li v-for="i in record.transactions" :key="i._id">
              (<b> {{ i.serial }}</b> ) {{ i.typeReglement }}
            </li>
          </template>

          <template slot="paymentOnWhat" slot-scope="text, record">
            <div class="d-inline" v-for="i in record.transactions" :key="i._id">
              <div v-if="i.fees">
                <a-tag color="blue" v-for="(value, key) in i.fees" :key="key">{{
                  formatFeeName(key)
                }}</a-tag>
              </div>
            </div>
          </template>
          <template slot="modePaiement" slot-scope="text, record">
            <a-button
              icon="search"
              type="primary"
              block
              v-if="record.transactions[0].cheques.length > 0"
              @click="chequeDetailsModal(record.transactions[0])"
            >
              {{ record.modePaiement }}
            </a-button>
            <div v-else>{{ record.modePaiement }}</div>
          </template>
          <template slot="date" slot-scope="text, record">
            {{ moment(record.transactions[0].date).format("DD/MM/YYYY") }}
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('paiement.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("scolarite.recherche") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <a-tag color="#2db7f5" slot="userName" slot-scope="text">
            {{ text ? text : "--" }}
          </a-tag>
          <a-button
            slot="print"
            type="primary"
            slot-scope="text, record"
            @click="() => print(record)"
          >
            <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
          </a-button>
        </a-table>
      </div>
    </div>
    <chequeModal
      v-if="chequeVisible"
      :chequeVisible="chequeVisible"
      :activeCheques="activeCheques"
      @close="handleClose"
    />
  </div>
</template>

<script>
/* eslint-disable */
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import { pdfHeader } from "@/utilities/pdfUtils";
import { formatFeeName } from "@/utilities/settings";
import chequeModal from "@/components/comptabilite/chequeModal";

Vue.use(VueRouter);

export default {
  props: {
    studentId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["settings"]),
  },
  components: {
    chequeModal,
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Liste de réglements",
    });

    await apiClient
      .get("/multitransaction/" + this.studentId)
      .then((res) => {
        this.nomClient = res.data.nomClient;
        this.studentName = res.data.studentName;
        this.data = res.data.formattedMultiTransactions;
        console.log(this.data);
      })
      .catch();
    this.cacheData = this.data.map((item) => ({ ...item }));
    this.rowData = this.data.map((item) => ({ ...item }));
    this.tableLoading = false;
    this.filtredTable = this.data;
    this.filtredTableCache = this.filtredTable;
  },

  data() {
    return {
      loadedChequeData: null,
      dateRangeData: ["", ""],
      mode: ["date", "date"],
      fields: [
        "serial",
        "nomClient",
        "typeReglement",
        "paiementSur",
        "modePaiement",
        "montantHT",
        "montantTTC",
        "datePaiement",
      ],
      editingKey: "",
      rowData: [],
      tableLoading: true,
      nomClient: "",
      studentName: "",
      visibleParentFile: false,
      data: [],
      parent: {},
      chequeVisible: false,
      activeCheques: {},
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      indeterminate: true,
      columns: [
        //numéro de série
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "multiTransactionSerial",
          key: "multiTransactionSerial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "multiTransactionSerial",
          },
          onFilter: (value, record) =>
            record.multiTransactionSerial
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title:
            "(" +
            this.$t("recette.numSerie") +
            ") " +
            this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.paiementQuoi"),
          dataIndex: "paymentOnWhat",
          key: "paymentOnWhat",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentOnWhat",
          },
          onFilter: (value, record) => {
            let result = false;
            try {
              for (const key of Object.keys(record.fees)) {
                if (
                  formatFeeName(key).toLowerCase().includes(value.toLowerCase())
                )
                  result = true;
              }
            } catch {
              result = true;
            }
            return result;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "modePaiement",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) =>
            record.modePaiement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHTVA"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) =>
            record.userName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
      ],
      filtredTable: [],
      filtredTableCache: [],
      printLoading: false,
    };
  },
  methods: {
    formatFeeName,
    handleClose() {
      this.chequeVisible = false;
    },
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);

        this.data = this.rowData.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });

        this.filtredTable = this.filtredTableCache.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });
      } else {
        this.data = this.rowData;
        this.filtredTable = this.filtredTableCache;
      }
    },
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));

      this.activeCheques = recordData.cheques;
      this.activeCheques.montantCheque = recordData.montantCheque;
      this.activeCheques.montantEspece = recordData.montantEspece;
      this.chequeVisible = true;

      this.activeCheques.transactionId = recordData._id;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filtredTableCache = extra.currentDataSource;
    },
    moment,

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    print(record) {
      console.log(record);
      this.$gtag.event("Imp one multi reglement", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:listeReglement",
        value: 1,
      });

      const tva = record.tva;
      const title = "Liste réglement";

      const doc = pdfHeader(this.settings, this.nomClient, title);
      var arr = [];
      const array = [];
      //ref on first transaction , multi payments transactions share the same basic data
      let firstTransition = record.transactions[0];
      let onTransReduction = firstTransition.onTransReduction
        ? firstTransition.onTransReduction / 100
        : 0;
      let baseReductionExist = record.transactions.some((transaction) => {
        const repReduction = transaction.payment.repReduction;
        if (
          repReduction &&
          typeof repReduction === "object" &&
          Object.keys(repReduction).length > 0
        ) {
          for (const month in transaction.info.months) {
            if (Number(repReduction[month])) {
              return true;
            }
          }
          if (transaction.onInscription && repReduction.inscription)
            return true;
        }
        return false;
      });
      let rowData = [0, 0, 0, 0];
      for (const t of record.transactions) {
        record = t;
        let repReduction = record.payment.repReduction;

        if (record.fees) {
          Object.keys(record.fees).forEach((k) => {
            arr.push(record.serial);
            arr.push(this.studentName);
            let feeName = formatFeeName(k);

            arr.push(feeName);
            arr.push(record.typeReglement);

            const fee = record.fees[k];

            if (baseReductionExist) {
              arr.push(
                (repReduction[k] ? Number(repReduction[k]).toFixed(2) : 0) + "%"
              );
            }

            arr.push(
              (record.typeFR == "F" ? fee - fee * record.tva : fee).toFixed(2)
            );

            array.push(arr);
            arr = [];
          });
        }

        //montantHT
        rowData[0] += record.montantHT;
        //montant reversed
        rowData[1] += record.montantTTC - record.montantHT - record.fiscal;
        //fiscal
        rowData[2] += record.fiscal;
        //TTC
        rowData[3] += record.montantTTC;
      }
      //affichier reduction si existe
      if (onTransReduction) {
        if (record.typeFR == "R") {
          arr.push({
            colSpan: baseReductionExist ? 5 : 4,
            content: "Total sans réduction",
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          arr.push({
            content: (rowData[3] / (1 - onTransReduction)).toFixed(2),
            styles: { valign: "middle", halign: "center", fontStyle: "bold" },
          });
          array.push(arr);
          arr = [];
        }
        arr.push({
          colSpan: baseReductionExist ? 5 : 4,
          content: "Réduction",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: (onTransReduction * 100).toFixed(2) + "%",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      //total footer
      let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
      for (let i = 0; i < 4; i++) {
        if (record.typeFR == "R") {
          i = 3;
          rowDataText[i] = "Total";
        }
        arr.push({
          colSpan: baseReductionExist ? 5 : 4,
          content: rowDataText[i],
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });

        arr.push({
          content: parseFloat(rowData[i]).toFixed(2),
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,
        head: baseReductionExist
          ? [
              [
                "Num",
                "Enfant",
                "Frais",
                "Désignation",
                "Réduction de base",
                "Montant",
              ],
            ]
          : [["Num", "Enfant", "Frais", "Désignation", "Montant"]],
        body: array,
        foot: [
          [
            {
              colSpan: baseReductionExist ? 6 : 5,
              content:
                "Le montant total est " +
                writtenNumber(rowData[3], { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });
      doc.save(title + ".pdf");
    },
  },
};
</script>
<style scoped>
.ant-descriptions-item-label {
  font-weight: bold !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
