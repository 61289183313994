<template>
  <div class="row p-0">
    <div class="col-md-8 pr-2">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12">
              <label style="margin-right: 15px">{{
                this.$t("emploi.seance")
              }}</label>

              <a-select
                default-value=""
                :allowClear="true"
                v-model="selectedSession"
                @change="onSessionChange"
                :placeholder="$t('emploi.seanceListe')"
                :loading="sessionLoading"
                :disabled="sessionLoading"
                style="width: 190px"
              >
                <a-select-option key="" value="">{{
                  $t("emploi.seanceListe")
                }}</a-select-option>
                <a-select-option
                  v-for="session in sessionList"
                  :key="session._id"
                  :value="session._id"
                >
                  {{ session.subject.name }}
                </a-select-option>
              </a-select>
              <a-button
                type="primary"
                style="margin-right: 5px; position: absolute; right: 0"
                :loading="printLoading"
                :disabled="printLoading"
                @click="generatePdf"
              >
                <a-icon v-if="!printLoading" type="file-pdf" />{{
                  $t("action.imprimer")
                }}
              </a-button>
            </div>
          </div>
          <a-divider></a-divider>
          <div class="row mb-2">
            <div class="m-auto" style="width: 95%">
              <a-range-picker
                format="DD-MM-YYYY"
                style="width: 98%"
                @change="
                  (val) => {
                    handleDateRange(val);
                  }
                "
              />
            </div>
          </div>

          <a-divider></a-divider>
          <!-- global table -->
          <a-table
            v-if="!selectedSession"
            :columns="columns"
            :data-source="activeData"
            :pagination="true"
            :loading="tableLoading"
          >
            <template slot="seance" slot-scope="text"
              ><a
                @click="
                  () => {
                    onSessionChange(text._id);
                  }
                "
                >{{ text.subject.name }}</a
              ></template
            >
            <template slot="nb_a" slot-scope="text"
              ><a-tag color="red">{{ text }}</a-tag></template
            >
            <template slot="nb_d" slot-scope="text"
              ><a-tag color="orange">{{ text }}</a-tag></template
            >
          </a-table>
          <a-table
            v-else
            :columns="columns2"
            :data-source="activeData2"
            :pagination="true"
            :loading="tableLoading"
          >
            <template slot="schedule" slot-scope="text, record">{{
              record.schedule.subject
            }}</template>
            <template slot="date" slot-scope="text"
              ><a-tag>{{ moment(text).format("DD/MM/YYYY HH:mm") }}</a-tag>
            </template>
            <template slot="type" slot-scope="text">
              <a-tag
                :color="
                  {
                    Retard: 'orange',
                    Absence: 'red',
                  }[text]
                "
                >{{ text }}</a-tag
              >
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div class="col-md-4 pl-2">
      <div class="card">
        <div class="card-body">
          <div style="text-align:center;margin-top;: 15px;">
            <h4>{{ $t("assiduite.assiduite") }}</h4>
          </div>
          <a-divider>
            <span class="font-size-18">{{ studentName }}</span>
          </a-divider>

          <attendanceChart
            :subject="selectedSubject"
            style="margin-bottom: 30px"
            :data="data"
            :studentName="studentName"
          />
          <attendanceBar
            :subject="selectedSubject"
            :data="data"
            :studentName="studentName"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import attendanceChart from "./components/attendanceChart";
import attendanceBar from "./components/attendanceBar";

export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  props: {
    studentId: {
      required: true,
    },
    classRoomId: {
      required: true,
    },
    studentName: {
      required: true,
    },
  },
  components: { attendanceChart, attendanceBar },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Assiduité",
    });

    this.tableLoading = true;

    this.sessionList = [];
    this.sessionLoading = true;
    const query = {
      classeRoom: this.classRoomId,
      status: { $ne: "inactive" },
    };

    await apiClient
      .post("/schedules/filter", {
        query: query,
        aggregation: [
          {
            $lookup: {
              from: "subjects",
              localField: "subject",
              foreignField: "_id",
              as: "subject",
            },
          },
          {
            $lookup: {
              from: "modules",
              localField: "module",
              foreignField: "_id",
              as: "module",
            },
          },
          {
            $lookup: {
              from: "modulespecifics",
              localField: "moduleSpecific",
              foreignField: "_id",
              as: "moduleSpecific",
            },
          },
          {
            $project: {
              _id: 1,
              time: 1,
              day: 1,
              subject: {
                $let: {
                  vars: {
                    subjectVar: {
                      $arrayElemAt: ["$subject", 0],
                    },
                  },
                  in: {
                    name: "$$subjectVar.name",
                    _id: "$$subjectVar._id",
                    status: "$$subjectVar.status",
                  },
                },
              },
              module: {
                $let: {
                  vars: {
                    moduleVar: {
                      $arrayElemAt: ["$module", 0],
                    },
                  },
                  in: {
                    name: "$$moduleVar.name",
                    _id: "$$moduleVar._id",
                    status: "$$moduleVar.status",
                  },
                },
              },
              moduleSpecific: {
                $let: {
                  vars: {
                    moduleSpecificVar: {
                      $arrayElemAt: ["$moduleSpecific", 0],
                    },
                  },
                  in: {
                    name: "$$moduleSpecificVar.name",
                    _id: "$$moduleSpecificVar._id",
                    status: "$$moduleSpecificVar.status",
                  },
                },
              },
              ends: 1,
              duration: 1,
              type: 1,
            },
          },
        ],
      })
      .then(({ data }) => {
        this.sessionList = [];
        data.map((s) => {
          if (!this.sessionList.find((d) => d._id == s._id)) {
            if (s.type == "MP") s.subject = s.module;
            if (s.type == "MS") s.subject = s.moduleSpecific;

            if (!this.sessionList.find((d) => d.subject._id == s.subject._id))
              this.sessionList.push(s);
          }
        });

        this.sessionList = this.sessionList.filter(
          (d) => d.subject.status !== "inactive"
        );

        this.sessionLoading = false;
        this.selectedSession = "";
      })
      .catch((e) => console.log(e));

    await apiClient
      .post("/attendance/filter", {
        query: { student: this.studentId },
      })
      .then(({ data }) => {
        data = data.filter(
          (s) => s.schedule && s.schedule.classRoomId == this.classRoomId
        );

        this.data = data.map((s) => {
          if (s.schedule.type == "MP") s.schedule.subject = s.schedule.module;
          if (s.schedule.type == "MS")
            s.schedule.subject = s.schedule.moduleSpecific;
          return s;
        });

        this.rowData = [...this.data];

        this.data.sort(
          (a, b) =>
            moment(b.date).format("YYYYMMDD") -
            moment(a.date).format("YYYYMMDD")
        );

        this.sessionList.map((s) => {
          const ab = this.data.filter(
            (a) => a.schedule.subject == s.subject.name && a.type == "Absence"
          ).length;
          const rt = this.data.filter(
            (a) => a.schedule.subject == s.subject.name && a.type == "Retard"
          ).length;
          this.activeData.push({ _id: s.id, seance: s, nb_a: ab, nb_d: rt });
        });
      });

    this.tableLoading = false;
  },
  async mounted() {
    //
  },
  data() {
    return {
      printLoading: false,
      sessionLoading: false,
      tableLoading: false,
      activeData: [],
      activeData2: [],
      data: [],
      rowData: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      searchedColumn: "",
      selectedSession: "",
      selectedSubject: "",
      columns: [
        {
          dataIndex: "seance",
          key: "seance",
          title: this.$t("emploi.seance"),
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "seance" },
        },
        {
          dataIndex: "nb_a",
          key: "nb_a",
          title: this.$t("assiduite.nb_absent"),
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "nb_a" },
        },
        {
          dataIndex: "nb_d",
          key: "nb_d",
          title: this.$t("assiduite.nb_delay"),
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "nb_d" },
        },
      ],
      columns2: [
        {
          dataIndex: "schedule",
          key: "seance",
          title: this.$t("emploi.seance"),
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "schedule" },
        },
        {
          dataIndex: "date",
          key: "date",
          title: this.$t("assiduite.date"),
          slots: { title: "date" },
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
        },
        {
          dataIndex: "type",
          key: "type",
          title: this.$t("recette.type"),
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "type" },
        },
      ],
      listModules: [],
    };
  },
  methods: {
    async generatePdf() {
      this.$gtag.event("Imp eleve assiduite", {
        event_category: "Impression PDF",
        event_label: "ProfileEleve:assiduite",
        value: 1,
      });

      this.printLoading = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      doc.setFontSize(20);
      let width = doc.getTextWidth("Feuille d'appel : " + this.studentName);
      doc.text(
        pdf_width / 2 - width / 2,
        37,
        "Feuille d'appel : " + this.studentName
      );
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 6, 6, 30, 30);
      doc.setFontSize(12);
      var arr = [];
      const array = [];

      if (!this.selectedSession) {
        for (let i = 0; i < this.activeData.length; i++) {
          arr.push(this.activeData[i].seance);
          arr.push(this.activeData[i].nb_a);
          arr.push(this.activeData[i].nb_d);
          array.push(arr);
          arr = [];
        }
        doc.autoTable({
          theme: "grid",
          styles: { font: "Amiri" },
          styles: { font: "Amiri" },
          margin: { top: 50 },
          head: [["Séance", "Nombre d'absences", "Nombre de retards"]],
          body: array,
          foot: [],
        });
      } else {
        for (let i = 0; i < this.activeData2.length; i++) {
          arr.push(this.activeData2[i].schedule.subject);
          arr.push(moment(this.activeData2[i].date).format("DD/MM/YYYY HH:mm"));
          arr.push(this.activeData2[i].type);
          array.push(arr);
          arr = [];
        }
        doc.autoTable({
          theme: "grid",
          styles: { font: "Amiri" },
          styles: { font: "Amiri" },
          margin: { top: 50 },
          head: [["Séance", "Date", "Type"]],
          body: array,
          foot: [],
        });
      }

      this.printLoading = false;

      doc.save("Feuille d'appel : " + this.studentName);
    },
    moment,
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);
        this.data = this.rowData.filter((s) =>
          moment(s.date).isBetween(start, end, "days", "[]")
        );
        this.updateTables();
      } else {
        this.data = [...this.rowData];
        this.updateTables();
      }
    },
    onSessionChange(k) {
      this.selectedSession = k;
      this.updateTables();
    },
    updateTables() {
      if (this.selectedSession) {
        this.activeData2 = [];
        const sb = this.sessionList.find((s) => s._id == this.selectedSession);
        this.selectedSubject = sb.subject.name;
        this.activeData2 = this.data.filter(
          (d) => d.schedule.subject == sb.subject.name
        );
      } else {
        this.selectedSubject = "";
      }
      this.activeData = [];
      this.sessionList.map((s) => {
        const ab = this.data.filter(
          (a) => a.schedule.subject == s.subject.name && a.type == "Absence"
        ).length;
        const rt = this.data.filter(
          (a) => a.schedule.subject == s.subject.name && a.type == "Retard"
        ).length;
        this.activeData.push({ _id: s.id, seance: s, nb_a: ab, nb_d: rt });
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.arabic-font {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
