<template>
  <div>

    <!--begin::Chart-->
    <apexchart class="statistics-widget-3-chart card-rounded-bottom" :options="options" :series="series" :height="300"
      type="bar"></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>

export default {
  props: ['data', 'studentName', 'subject'],
  data() {
    return {
      options: {},
      series: [],
      labelColor: '#3F4254',
      colors: ['#D9214Ebb', '#ffc107bb'],
      lightColors: ['#D9214E', '#ffc107'],
    }
  },

  watch: {
    data: function (value) {
      this.updateChart()
    },

    studentName: function (value) {
      this.updateChart()
    },

    subject: function () {
      this.updateChart()
    },
  },

  created() {
    this.updateChart()
  },

  methods: {
    updateChart() {
      const attendance = this.data
      let a = 0
      let d = 0
      attendance.filter((a) => !this.subject || a.schedule.subject == this.subject).map((att) => {
        if (att.type == 'Retard') d++
        else if (att.type == 'Absence') a++
      })

      const serie = [a, d]

      this.updateOptions(['Absence', 'Retard'])
      this.updateSeries(serie)
    },

    updateSeries(serie) {
      this.series = [{
        data: serie,
        name: '',
        colors: this.colors,
      }]
    },
    updateOptions(types) {
      this.options = {
        chart: {
          height: 300,
          type: 'bar',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: this.colors,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: this.colors,
        },
        xaxis: {
          type: 'string',
          categories: types,
          labels: {
            show: false,
            style: {
              colors: this.labelColor,
              fontSize: '12px',
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {},
        },
        fill: {
          colors: this.colors,
        },
        colors: this.colors,
        markers: {
          colors: this.colors,
          strokeColor: this.lightColors,
          strokeWidth: 3,
        },
      }
    },
  },
}
</script>
