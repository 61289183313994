<template>
  <profile :studentId="studentId" :key="reloads" v-on:reload="reload"></profile>
</template>
<script>
/* eslint-disable */
import profile from "./profile";
export default {
  components: {
    profile,
  },
  data() {
    return { reloads: 0 };
  },
  props: {
    studentId: {
      required: true,
    },
  },
  methods: {
    reload() {
      this.reloads = this.reloads + 1;
    },
  },
};
</script>